// const grayRamp = "█▓▒░▐▌▀■◘○◙•♠♥♦♣☺☻♂♀♪♫§@WM8&0NQDBXR#K$VG9HPU6EA5Z4SY2FJTwCmkLIo3nxzuvarjft1()[]?-+!<>~i;:,=\"^`'. ";
export const grayRampLight =
  '█▓▒░$@B%8&WM#*oahkbdpqwmZO0QLCJUYXzcvunxrjft/\\|()1{}[]?-_+~<>i!lI;:,"^`\'. '
export const grayRampDark = grayRampLight.split('').reverse().join('')

export const CHARSET_ASCII_ART = grayRampDark.concat('\n')
export const CHARSET_STANDARD = 'abcdefghijklmnopqrstuvwxyz .,?!\n'
  .concat('0123456789ABCDEFGHIKLMNOPRSTUVWY')
  .concat('JQXZ"#$%&\'()*+-/:;<=>@[\\]^_`{|}~')
  .concat('\t░▒▓█▄▌▐▀■☺☻♥♦♣♠•◘○◙♂♀♪♫►◄▲▼¶§¿¡')
