// textAnalyzerWorkerHelper.js
import Worker from '../workers/textAnalyzer.worker.js'
class TextAnalyzerWorkerHelper {
  constructor() {
    this.worker = new Worker()
  }

  analyze(text) {
    return new Promise((resolve, reject) => {
      const messageHandler = e => {
        this.worker.removeEventListener('message', messageHandler)
        resolve(e.data)
      }

      const errorHandler = error => {
        this.worker.removeEventListener('error', errorHandler)
        reject(error)
      }

      this.worker.addEventListener('message', messageHandler)
      this.worker.addEventListener('error', errorHandler)

      this.worker.postMessage(text)
    })
  }

  terminate() {
    this.worker.terminate()
  }
}

export default TextAnalyzerWorkerHelper
