import React, { useState, useEffect, useCallback } from 'react'
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs
} from '@mui/material'

import TextAnalyzerWorkerHelper from './utils/textAnalyzerWorkerHelper'
import AsciiArtEncoderWorkerHelper from './utils/asciiArtEncoderWorkerHelper'
import AsciiArtDecoderWorkerHelper from './utils/asciiArtDecoderWorkerHelper'

import AsciiArtGenerator from './components/AsciiArtGenerator'
import DigitalRain from './components/DigitalRain'
import './App.css'
import { CHARSET_ASCII_ART, CHARSET_STANDARD } from './utils/constants'

const TextAnalyzer = () => {
  const [text, setText] = useState('')
  const [finalText, setFinalText] = useState('')
  const [analysisResults, setAnalysisResults] = useState(null)
  const [customEncodingResults, setCustomEncodingResults] = useState(null)
  const [defaultEncodingResults, setDefaultEncodingResults] = useState(null)
  const [decodedText, setDecodedText] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState(null)
  const [analyzerWorker, setAnalyzerWorker] = useState(null)
  const [encoderWorker, setEncoderWorker] = useState(null)
  const [decoderWorker, setDecoderWorker] = useState(null)

  const [tabValue, setTabValue] = useState(0)

  const asciiArtTabSelected = tabValue === 1

  useEffect(() => {
    const newAnalyzerWorker = new TextAnalyzerWorkerHelper()

    const newAsciiArtEncoderWorker = new AsciiArtEncoderWorkerHelper()
    const newAsciiArtDecoderWorker = new AsciiArtDecoderWorkerHelper()
    setAnalyzerWorker(newAnalyzerWorker)
    setEncoderWorker(newAsciiArtEncoderWorker)
    setDecoderWorker(newAsciiArtDecoderWorker)

    return () => {
      newAnalyzerWorker.terminate()
      newAsciiArtEncoderWorker.terminate()
      newAsciiArtDecoderWorker.terminate()
    }
  }, [])

  const handleProcess = useCallback(async () => {
    if (isProcessing) return
    const textToUse = text
    setFinalText(textToUse)
    if (text.trim() === '') {
      setError('Please enter some text to analyze and encode.')
      return
    }
    setIsProcessing(true)
    setError(null)
    setAnalysisResults(null)
    setCustomEncodingResults(null)
    setDefaultEncodingResults(null)
    setDecodedText('')

    try {
      const analysis = await analyzerWorker.analyze(textToUse)
      const { success, suggestedCharsets } = analysis
      setAnalysisResults({
        success,
        suggestedCharsets: [
          suggestedCharsets.slice(0, 32),
          suggestedCharsets.slice(32, 64),
          suggestedCharsets.slice(64, 96),
          suggestedCharsets.slice(96, 128)
        ]
      })

      const customEncoding = await encoderWorker.encode(
        textToUse,
        suggestedCharsets
      )
      setCustomEncodingResults(customEncoding)
      const defaultEncoding = await encoderWorker.encode(
        textToUse,
        asciiArtTabSelected ? CHARSET_ASCII_ART : CHARSET_STANDARD
      )
      setDefaultEncodingResults(defaultEncoding)

      const decoding = await decoderWorker.decode(
        customEncoding.encoded,
        suggestedCharsets
      )
      setDecodedText(decoding.decoded)
      if (decoding.decoded === textToUse) {
        console.log('Success decoding')
      } else {
        throw new Error('Decoded text does not match original text')
      }
    } catch (err) {
      setError('An error occurred: ' + err.message)
    } finally {
      setIsProcessing(false)
    }
  }, [isProcessing, text, analyzerWorker, encoderWorker, decoderWorker])

  const handleAsciiGenerated = useCallback(
    async asciiArt => {
      setText(asciiArt)
      // await handleProcess()
    },
    [handleProcess]
  )

  const CompressionResultsTable = ({ customResults, defaultResults }) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Metric</TableCell>
          <TableCell>Custom Charsets</TableCell>
          <TableCell>Default Charsets</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Original Size</TableCell>
          <TableCell>{customResults.originalSize} bytes</TableCell>
          <TableCell>{defaultResults.originalSize} bytes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Encoded Size</TableCell>
          <TableCell>{customResults.encodedSize} bytes</TableCell>
          <TableCell>{defaultResults.encodedSize} bytes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Compression Ratio</TableCell>
          <TableCell>{customResults.compressionRatio.toFixed(2)}%</TableCell>
          <TableCell>{defaultResults.compressionRatio.toFixed(2)}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Space Saved</TableCell>
          <TableCell>
            {customResults.originalSize - customResults.encodedSize} bytes
          </TableCell>
          <TableCell>
            {defaultResults.originalSize - defaultResults.encodedSize} bytes
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Text Analyzer and Encoder
        </Typography>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{ mb: 2 }}
        >
          <Tab label="Text Input" />
          <Tab label="ASCII Art Generator" />
        </Tabs>
        {tabValue === 0 ? (
          <TextField
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="Enter text to analyze and encode..."
            margin="normal"
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              lineHeight: 1,
              overflow: 'auto'
            }}
          />
        ) : (
          <AsciiArtGenerator onAsciiGenerated={handleAsciiGenerated} />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleProcess}
          disabled={isProcessing}
          startIcon={
            isProcessing ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          {isProcessing ? 'Processing...' : 'Analyze and Encode Text'}
        </Button>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {analysisResults && analysisResults.success && (
          <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Analysis Results
            </Typography>
            {analysisResults.suggestedCharsets.map((charset, index) => (
              <Box key={index} mb={2}>
                <Typography variant="subtitle1">
                  Charset {index + 1} ({charset.length} items):
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                  {charset.join('')}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}

        {customEncodingResults && defaultEncodingResults && (
          <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Encoding Results
            </Typography>
            <CompressionResultsTable
              customResults={customEncodingResults}
              defaultResults={defaultEncodingResults}
            />
          </Paper>
        )}

        {decodedText && (
          <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Decoded Text
            </Typography>

            {!asciiArtTabSelected ? (
              <Typography
                variant="body1"
                component="pre"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  lineHeight: 1,
                  overflow: 'auto'
                }}
              >
                {decodedText}
              </Typography>
            ) : (
              <>
                <DigitalRain
                  asciiArt={decodedText}
                  fontSize={12} // Match this to your Typography component's font size
                  canvasProps={{
                    id: 'digital-rain-canvas',
                    style: {
                      // maxWidth: '100%',
                      // maxHeight: '70vh'
                    }
                  }}
                />
              </>
            )}
            {decodedText === finalText ? (
              <Alert severity="success" sx={{ mt: 2 }}>
                The decoded text matches the original input!
              </Alert>
            ) : (
              <Alert severity="error" sx={{ mt: 2 }}>
                The decoded text does not match the original input.
              </Alert>
            )}
          </Paper>
        )}
      </Box>
    </Container>
  )
}

export default TextAnalyzer
