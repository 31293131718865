import React, { useRef, useEffect, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { grayRampDark } from '../utils/constants'

/**
 * Converts a hex color string to an RGB object.
 * @param {string} hex - The hex color string.
 * @returns {{ r: number, g: number, b: number }} - The RGB representation.
 */
const hexToRgb = hex => {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return { r, g, b }
}

/**
 * Generates a shade of the base color based on a fraction.
 * If the base color is black, generates a grayscale color.
 * @param {string} baseColor - The base color in hex format.
 * @param {number} fraction - The fraction (0 to 1) to scale the color.
 * @returns {string} - The shaded color in hex format.
 */
const getColorShade = (baseColor, fraction) => {
  const baseRgb = hexToRgb(baseColor)
  const isBlack = baseRgb.r === 0 && baseRgb.g === 0 && baseRgb.b === 0

  if (isBlack) {
    // Generate a grayscale color
    const grayValue = Math.floor(255 * fraction)
    return (
      '#' +
      ((1 << 24) + (grayValue << 16) + (grayValue << 8) + grayValue)
        .toString(16)
        .slice(1)
    )
  } else {
    // Scale the base color
    const r = Math.round(baseRgb.r * fraction)
    const g = Math.round(baseRgb.g * fraction)
    const b = Math.round(baseRgb.b * fraction)
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  }
}

/**
 * Interpolates between two hex colors based on a percentage.
 * @param {string} color1 - The starting hex color.
 * @param {string} color2 - The ending hex color.
 * @param {number} percent - The interpolation factor between 0 and 1.
 * @returns {string} - The interpolated hex color.
 */
function interpolate(color1, color2, percent) {
  const r1 = parseInt(color1.substring(1, 3), 16)
  const g1 = parseInt(color1.substring(3, 5), 16)
  const b1 = parseInt(color1.substring(5, 7), 16)

  const r2 = parseInt(color2.substring(1, 3), 16)
  const g2 = parseInt(color2.substring(3, 5), 16)
  const b2 = parseInt(color2.substring(5, 7), 16)

  const r = Math.round(r1 + (r2 - r1) * percent)
  const g = Math.round(g1 + (g2 - g1) * percent)
  const b = Math.round(b1 + (b2 - b1) * percent)

  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

/**
 * Brightens a hex color by a brightness factor.
 * @param {string} color - The base color in hex format.
 * @param {number} brightness - The brightness factor.
 * @returns {string} - The brightened color in hex format.
 */
const brightenColor = (color, brightness) => {
  const { r, g, b } = hexToRgb(color)

  const brightenedColor = {
    r: Math.min(255, Math.floor(r * brightness)),
    g: Math.min(255, Math.floor(g * brightness)),
    b: Math.min(255, Math.floor(b * brightness))
  }

  return (
    '#' +
    (
      (1 << 24) +
      (brightenedColor.r << 16) +
      (brightenedColor.g << 8) +
      brightenedColor.b
    )
      .toString(16)
      .slice(1)
  )
}

/**
 * Determines the character to draw based on the original character and fade progress.
 * If the character is a space and fade progress is less than 1, it returns a block character.
 * @param {string} char - The original character from the ASCII art.
 * @param {number} fadeProgress - The fade progress (0 to 1).
 * @returns {string} - The character to draw.
 */
const getCharToDraw = (char, fadeProgress) => {
  if (char === ' ' && fadeProgress < 1) {
    return '░' // Use block character during animation
  } else {
    return char
  }
}

/**
 * Determines the color of a character based on fade progress.
 * @param {string} char - The character to color.
 * @param {number} fadeProgress - The fade progress (0 to 1).
 * @param {string} dropColor - The drop color in hex format.
 * @param {string} baseColor - The base color in hex format.
 * @returns {string} - The interpolated color in hex format.
 */
const getColorFromChar = (char, fadeProgress, dropColor, baseColor) => {
  const index = grayRampDark.indexOf(char)
  const fraction = index === -1 ? 1 : index / (grayRampDark.length - 1)
  const targetColor = getColorShade(baseColor, fraction)

  const clampedProgress = Math.min(1.0, Math.max(0.0, fadeProgress))

  // Interpolate between dropColor and targetColor
  const interpolatedColor = interpolate(dropColor, targetColor, clampedProgress)

  return interpolatedColor
}

/**
 * DigitalRain Component
 * Renders an ASCII art with a digital rain animation effect.
 */
const DigitalRain = ({
  asciiArt,
  fontSize = 12,
  color,
  grayRamp = grayRampDark,
  animationDuration = 300,
  canvasProps = {}
}) => {
  const backgroundCanvasRef = useRef(null)
  const foregroundCanvasRef = useRef(null)
  const previousOffCanvasRef = useRef(null) // Holds the previous offscreen canvas
  const animationRef = useRef(null)
  const fadeProgressLevelsRef = useRef([]) // 2D array for fade progress levels
  const previousAsciiArtRef = useRef(null)
  const dropConfigsRef = useRef([]) // Holds drop configurations
  const startTimeRef = useRef(null)

  const theme = useTheme()
  color = color || theme.palette.primary.main

  const dropColor = brightenColor(color, 2.5) // Brighten the drop color

  /**
   * Memoize the ASCII matrix and related calculations to prevent unnecessary recalculations.
   */
  const {
    asciiMatrix,
    numRows,
    numCols,
    charWidth,
    charHeight,
    canvasWidth,
    canvasHeight
  } = useMemo(() => {
    if (!asciiArt) return {}

    const lines = asciiArt.split('\n')
    const rows = lines.length
    const cols = Math.max(...lines.map(line => line.length))

    const cw = fontSize * 0.6 // Character width
    const ch = fontSize // Character height

    const width = cols * cw
    const height = rows * ch

    // Prepare ASCII matrix
    const matrix = []
    for (let row = 0; row < rows; row++) {
      matrix[row] = []
      const line = lines[row]
      for (let col = 0; col < cols; col++) {
        const char = line[col] || ' '
        matrix[row][col] = char
      }
    }

    return {
      asciiMatrix: matrix,
      numRows: rows,
      numCols: cols,
      charWidth: cw,
      charHeight: ch,
      canvasWidth: width,
      canvasHeight: height
    }
  }, [asciiArt, fontSize])

  useEffect(() => {
    if (!asciiArt || !asciiMatrix) return

    const backgroundCanvas = backgroundCanvasRef.current
    const foregroundCanvas = foregroundCanvasRef.current
    if (!backgroundCanvas || !foregroundCanvas) return

    const backgroundCtx = backgroundCanvas.getContext('2d')
    const foregroundCtx = foregroundCanvas.getContext('2d')

    // Set canvas dimensions
    ;[backgroundCanvas, foregroundCanvas].forEach(canvas => {
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      canvas.style.width = canvasProps.style?.width || '100%'
      canvas.style.height = canvasProps.style?.height || 'auto'
    })

    // Initialize contexts
    ;[backgroundCtx, foregroundCtx].forEach(ctx => {
      ctx.font = `${fontSize}px 'Courier New', monospace`
      ctx.textBaseline = 'top'
    })

    // Initialize fade progress levels to 1 (fully faded in)
    fadeProgressLevelsRef.current = Array.from({ length: numRows }, () =>
      Array(numCols).fill(1)
    )

    // Create offscreen canvas for the new ASCII art
    const currentOffCanvas = document.createElement('canvas')
    currentOffCanvas.width = canvasWidth
    currentOffCanvas.height = canvasHeight
    const offCtx = currentOffCanvas.getContext('2d')
    offCtx.font = `${fontSize}px 'Courier New', monospace`
    offCtx.textBaseline = 'top'

    // Fill the offscreen canvas with black
    offCtx.fillStyle = '#000000'
    offCtx.fillRect(0, 0, canvasWidth, canvasHeight)

    // Draw the new asciiArt onto currentOffCanvas
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        const char = getCharToDraw(asciiMatrix[row][col], 1)
        const x = col * charWidth
        const y = row * charHeight
        const charColor = getColorFromChar(
          char,
          1, // Fade progress is 1 (fully faded in)
          dropColor,
          color
        )
        offCtx.fillStyle = charColor
        offCtx.fillText(char, x, y)
      }
    }

    // Fill background canvas with black
    backgroundCtx.fillStyle = '#000000'
    backgroundCtx.fillRect(0, 0, canvasWidth, canvasHeight)

    // Draw the previous image onto the background canvas if available
    if (previousAsciiArtRef.current && previousOffCanvasRef.current) {
      const prevOffCanvas = previousOffCanvasRef.current
      const prevWidth = prevOffCanvas.width
      const prevHeight = prevOffCanvas.height

      const aspectRatioPrev = prevWidth / prevHeight
      const aspectRatioNew = canvasWidth / canvasHeight

      let drawWidth, drawHeight, offsetX, offsetY

      if (aspectRatioPrev > aspectRatioNew) {
        // Previous image is wider relative to the new canvas
        drawWidth = canvasWidth
        drawHeight = canvasWidth / aspectRatioPrev
        offsetX = 0
        offsetY = (canvasHeight - drawHeight) / 2
      } else {
        // Previous image is taller relative to the new canvas
        drawHeight = canvasHeight
        drawWidth = canvasHeight * aspectRatioPrev
        offsetX = (canvasWidth - drawWidth) / 2
        offsetY = 0
      }

      backgroundCtx.drawImage(
        prevOffCanvas,
        0,
        0,
        prevWidth,
        prevHeight,
        offsetX,
        offsetY,
        drawWidth,
        drawHeight
      )
    }

    // Update previousOffCanvasRef to the current offCanvas
    previousOffCanvasRef.current = currentOffCanvas
    previousAsciiArtRef.current = asciiArt

    // Initialize drop configurations with randomness
    const dropConfigs = []
    for (let col = 0; col < numCols; col++) {
      // Random delay for each drop within the animation duration
      const delay = Math.random() * animationDuration * 0.5 // Delays up to half the duration
      dropConfigs.push({
        col,
        delay, // in milliseconds
        completed: false
      })
    }
    dropConfigsRef.current = dropConfigs

    // Cancel any previous animation
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current)
    }

    // Reset start time
    startTimeRef.current = null

    /**
     * Animation loop to handle drop movement and character fading.
     * @param {DOMHighResTimeStamp} currentTime - The current time.
     */
    const drawFrame = currentTime => {
      if (!startTimeRef.current) {
        startTimeRef.current = currentTime
      }
      const elapsedTime = currentTime - startTimeRef.current

      // Clear the foreground canvas
      foregroundCtx.clearRect(0, 0, canvasWidth, canvasHeight)

      // Track if any drop is still animating or any character is still fading
      let anyDropAnimating = false

      // Iterate over each drop
      dropConfigsRef.current.forEach(drop => {
        const { col, delay, completed } = drop

        const x = col * charWidth

        if (completed) {
          foregroundCtx.drawImage(
            currentOffCanvas,
            x,
            0,
            charWidth,
            canvasHeight, // Source rectangle from offscreen canvas
            x,
            0,
            charWidth,
            canvasHeight // Destination rectangle on foreground canvas
          )
          return
        }

        const dropElapsed = elapsedTime - delay
        if (dropElapsed < 0) {
          // Drop hasn't started yet
          anyDropAnimating = true
          return
        }

        const progress = Math.min(dropElapsed / animationDuration, 1) // Clamp between 0 and 1

        if (progress < 1) {
          anyDropAnimating = true
        } else {
          drop.completed = true
        }

        // Calculate the current drop position
        const dropPosition = progress * numRows
        const y = Math.floor(dropPosition) * charHeight

        // Draw characters up to the drop position
        foregroundCtx.drawImage(
          currentOffCanvas,
          x,
          0,
          charWidth,
          y, // Source rectangle from offscreen canvas
          x,
          0,
          charWidth,
          y // Destination rectangle on foreground canvas
        )

        // **Drawing Character at Drop Position**
        if (dropPosition < numRows) {
          const row = Math.floor(dropPosition)
          const char = getCharToDraw(asciiMatrix[row][col], 0)
          const charColor = getColorFromChar(
            char,
            0, // Fade progress starts at 0
            dropColor,
            color
          )

          foregroundCtx.fillStyle = charColor
          foregroundCtx.fillText(char, x, y)

          // Set fade progress to 0
          fadeProgressLevelsRef.current[row][col] = 0
        } else {
          // **Drop has reached the bottom**
          // Ensure fade progress is at 1
          for (let row = 0; row < numRows; row++) {
            fadeProgressLevelsRef.current[row][col] = 1
          }
        }

        // **Gradually Fade Characters After the Drop has Passed**

        const fadeSpeed = 0.1 // Adjust this value for faster/slower fading
        const maxRow = Math.ceil(dropPosition)
        // Only fade if the drop is still within the canvas
        for (let row = 0; row < maxRow; row++) {
          if (fadeProgressLevelsRef.current[row][col] < 1) {
            // Increment fade progress
            fadeProgressLevelsRef.current[row][col] = Math.min(
              fadeProgressLevelsRef.current[row][col] + fadeSpeed,
              1
            )

            const fadeProgress = fadeProgressLevelsRef.current[row][col]
            const fadeChar = getCharToDraw(asciiMatrix[row][col], fadeProgress)
            const fadeColor = getColorFromChar(
              fadeChar,
              fadeProgress,
              dropColor,
              color
            )
            const fadeY = row * charHeight

            foregroundCtx.fillStyle = fadeColor
            foregroundCtx.fillText(fadeChar, x, fadeY)

            // If any character is still fading, keep the animation running
            if (fadeProgress < 1) {
              anyDropAnimating = true
            }
          } else {
            // Character has finished fading
            const fadeChar = getCharToDraw(asciiMatrix[row][col], 1)
            const fadeColor = getColorFromChar(fadeChar, 1, dropColor, color)
            const fadeY = row * charHeight

            foregroundCtx.fillStyle = fadeColor
            foregroundCtx.fillText(fadeChar, x, fadeY)
          }
        }
      })

      if (anyDropAnimating) {
        animationRef.current = requestAnimationFrame(drawFrame)
      } else {
        // Animation complete, draw full new image onto background
        foregroundCtx.clearRect(0, 0, canvasWidth, canvasHeight)
        backgroundCtx.clearRect(0, 0, canvasWidth, canvasHeight)
        backgroundCtx.fillStyle = '#000000'
        backgroundCtx.fillRect(0, 0, canvasWidth, canvasHeight)
        backgroundCtx.drawImage(currentOffCanvas, 0, 0)
      }
    }

    // Start the animation
    animationRef.current = requestAnimationFrame(drawFrame)

    // Cleanup on unmount or when asciiArt changes
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [
    asciiArt,
    asciiMatrix,
    numRows,
    numCols,
    charWidth,
    charHeight,
    canvasWidth,
    canvasHeight,
    fontSize,
    grayRamp,
    animationDuration,
    canvasProps.style,
    dropColor,
    color
  ])

  return (
    <div
      style={{
        display: 'block',
        maxWidth: '100%',
        position: 'relative',
        width: canvasProps.style?.width || '100%',
        height: canvasProps.style?.height || 'auto',
        ...canvasProps.style
      }}
    >
      <canvas
        ref={backgroundCanvasRef}
        style={{
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          height: 'auto'
        }}
      />
      <canvas
        ref={foregroundCanvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'block',
          maxWidth: '100%',
          height: 'auto'
        }}
      />
    </div>
  )
}

export default DigitalRain
