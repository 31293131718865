import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
  Button,
  Typography,
  Box,
  Slider,
  Grid,
  Switch,
  FormControlLabel
} from '@mui/material'
import DigitalRain from './DigitalRain' // Import the new component
import { grayRampDark } from '../utils/constants'
import ColourAdjustmentWorkerHelper from '../utils/colourAdjustmentWorkerHelper'
import GrayscaleAdjustmentWorkerHelper from '../utils/grayscaleAdjustmentWorkerHelper'
import AsciiArtGeneratorWorkerHelper from '../utils/asciiArtGeneratorWorkerHelper'
import useEffectDebounced from './useEffectDebounced'

const MAX_CANVAS_WIDTH = 300
const MAX_CANVAS_HEIGHT = 300
const MAX_ASCII_WIDTH = 300

const AsciiArtGenerator = ({ onAsciiGenerated }) => {
  const [asciiArt, setAsciiArt] = useState('')
  const [image, setImage] = useState(null)
  const [brightness, setBrightness] = useState(100)
  const [contrast, setContrast] = useState(0)
  const [saturation, setSaturation] = useState(100)
  const [hue, setHue] = useState(0)
  const [sharpness, setSharpness] = useState(0)
  const [inverted, setInverted] = useState(false)
  const [posterizeLevels, setPosterizeLevels] = useState(16) // Default to 16 levels
  const [outputWidth, setOutputWidth] = useState(Math.floor(MAX_ASCII_WIDTH / 2))
  const colorCanvasRef = useRef(null)
  const grayscaleCanvasRef = useRef(null)
  const fileInputRef = useRef(null)

  const colorWorkerRef = useRef(null)
  const grayscaleWorkerRef = useRef(null)
  const asciiWorkerRef = useRef(null)
  const abortControllerRef = useRef(null)

  useEffect(() => {
    colorWorkerRef.current = new ColourAdjustmentWorkerHelper()
    grayscaleWorkerRef.current = new GrayscaleAdjustmentWorkerHelper()
    asciiWorkerRef.current = new AsciiArtGeneratorWorkerHelper()

    return () => {
      colorWorkerRef.current.terminate()
      grayscaleWorkerRef.current.terminate()
      asciiWorkerRef.current.terminate()
    }
  }, [])

  const resetSliders = () => {
    setBrightness(100)
    setContrast(0)
    setSaturation(100)
    setHue(0)
    setSharpness(0)
    setInverted(false)
    setOutputWidth(Math.floor(MAX_ASCII_WIDTH / 2))
    setPosterizeLevels(16)
  }

  const getFontRatio = () => {
    const pre = document.createElement('pre')
    pre.style.display = 'inline'
    pre.textContent = ' '
    document.body.appendChild(pre)
    const { width, height } = pre.getBoundingClientRect()
    document.body.removeChild(pre)
    return height / width
  }

  const calculateCanvasDimensions = (width, height) => {
    const aspectRatio = width / height
    let canvasWidth = width
    let canvasHeight = height

    if (canvasWidth > MAX_CANVAS_WIDTH) {
      canvasWidth = MAX_CANVAS_WIDTH
      canvasHeight = canvasWidth / aspectRatio
    }

    if (canvasHeight > MAX_CANVAS_HEIGHT) {
      canvasHeight = MAX_CANVAS_HEIGHT
      canvasWidth = canvasHeight * aspectRatio
    }

    return [Math.round(canvasWidth), Math.round(canvasHeight)]
  }

  const clampDimensions = useCallback(
    (width, height) => {
      const fontRatio = getFontRatio()
      const aspectRatio = width / height

      // Calculate target height based on outputWidth and aspect ratios
      let targetWidth = outputWidth
      let targetHeight = Math.round(outputWidth / aspectRatio / fontRatio)

      // Ensure dimensions are at least 1
      targetWidth = Math.max(1, targetWidth)
      targetHeight = Math.max(1, targetHeight)

      return [targetWidth, targetHeight]
    },
    [outputWidth]
  )

  const grayRamp = grayRampDark

  const handleFileChange = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = event => {
      const img = new Image()
      img.onload = () => {
        setImage(img)
        resetSliders()
      }
      img.src = event.target.result
    }
    reader.readAsDataURL(file)
  }

  const applyImageAdjustments = useCallback(
    async (context, width, height) => {
      const imageData = context.getImageData(0, 0, width, height)
      const adjustedImageData = await colorWorkerRef.current.adjustColours({
        imageData: imageData.data,
        width,
        height,
        brightness,
        contrast,
        saturation,
        hue,
        sharpness,
        inverted
      })
      return new ImageData(
        new Uint8ClampedArray(adjustedImageData),
        width,
        height
      )
    },
    [brightness, contrast, hue, inverted, saturation, sharpness]
  )

  const convertToGrayScales = useCallback(
    async imageData => {
      const result = await grayscaleWorkerRef.current.grayscale({
        imageData: imageData.data,
        posterizeLevels
      })
      return {
        grayScales: result.grayScales,
        grayscaleImageData: new ImageData(
          new Uint8ClampedArray(result.grayscaleImageData),
          imageData.width,
          imageData.height
        )
      }
    },
    [posterizeLevels]
  )

  const drawAscii = useCallback(
    async (grayScales, width, height) => {
      const ascii = await asciiWorkerRef.current.generateAsciiArt({
        grayScales,
        width,
        height,
        grayRamp
      })
      setAsciiArt(ascii)
      onAsciiGenerated(ascii)
    },
    [grayRamp, onAsciiGenerated]
  )

  const processImage = useCallback(
    async signal => {
      if (!image) return

      try {
        // Calculate canvas dimensions for previews (maintain aspect ratio)
        const [canvasWidth, canvasHeight] = calculateCanvasDimensions(
          image.width,
          image.height
        )

        // Color preview
        const colorCanvasOffscreen = new OffscreenCanvas(
          canvasWidth,
          canvasHeight
        )
        const colorContextOffscreen = colorCanvasOffscreen.getContext('2d')

        colorContextOffscreen.drawImage(image, 0, 0, canvasWidth, canvasHeight)

        if (signal.aborted) return

        const adjustedImageData = await applyImageAdjustments(
          colorContextOffscreen,
          canvasWidth,
          canvasHeight
        )

        if (signal.aborted) return

        const colorCanvas = colorCanvasRef.current
        const colorContext = colorCanvas.getContext('2d')
        colorCanvas.width = canvasWidth
        colorCanvas.height = canvasHeight
        colorContext.putImageData(adjustedImageData, 0, 0)

        // Grayscale preview
        const grayscaleCanvas = grayscaleCanvasRef.current
        const grayscaleContext = grayscaleCanvas.getContext('2d')
        grayscaleCanvas.width = canvasWidth
        grayscaleCanvas.height = canvasHeight
        if (signal.aborted) return
        const { grayscaleImageData } = await convertToGrayScales(
          adjustedImageData
        )

        if (signal.aborted) return

        grayscaleContext.putImageData(grayscaleImageData, 0, 0)

        const [asciiWidth, asciiHeight] = clampDimensions(
          image.width,
          image.height
        )
        const asciiCanvas = document.createElement('canvas')
        asciiCanvas.width = asciiWidth
        asciiCanvas.height = asciiHeight
        const asciiContext = asciiCanvas.getContext('2d')
        asciiContext.drawImage(image, 0, 0, asciiWidth, asciiHeight)

        if (signal.aborted) return

        const asciiImageData = await applyImageAdjustments(
          asciiContext,
          asciiWidth,
          asciiHeight
        )

        if (signal.aborted) return

        const { grayScales } = await convertToGrayScales(asciiImageData)

        if (signal.aborted) return

        // Generate ASCII art
        await drawAscii(grayScales, asciiWidth, asciiHeight)
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Image processing was cancelled')
        } else {
          console.error('Error during image processing:', error)
        }
      }
    },
    [
      image,
      calculateCanvasDimensions,
      applyImageAdjustments,
      convertToGrayScales,
      clampDimensions,
      drawAscii
    ]
  )

  useEffectDebounced(
    () => {
      if (image) {
        // Cancel any ongoing processing
        if (abortControllerRef.current) {
          abortControllerRef.current.abort()
        }

        // Create a new AbortController for this processing cycle
        abortControllerRef.current = new AbortController()

        processImage(abortControllerRef.current.signal)
      }
    },
    [
      image,
      brightness,
      contrast,
      saturation,
      hue,
      sharpness,
      inverted,
      outputWidth,
      posterizeLevels,
      processImage
    ],
    50
  )

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        onClick={() => fileInputRef.current.click()}
        sx={{ mb: 2 }}
      >
        Select Image
      </Button>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {/* Left side: Image previews */}
            <Box>
              {image && (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography gutterBottom>Color Preview</Typography>
                      <canvas
                        ref={colorCanvasRef}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          height: 'auto'
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom>Grayscale Preview</Typography>
                      <canvas
                        ref={grayscaleCanvasRef}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          height: 'auto'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {asciiArt && (
                <Box mt={2}>
                  <DigitalRain
                    asciiArt={asciiArt}
                    fontSize={12}
                    // color="#0f0"
                    grayRamp={grayRamp}
                    canvasProps={{
                      style: {
                        // maxWidth: '100%',
                        // maxHeight: '70vh',
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={4}>
            {/* Right side: Image tweaking controls */}
            <Box>
              {image && (
                <>
                  <Typography gutterBottom>Brightness</Typography>
                  <Slider
                    value={brightness}
                    onChange={(e, newValue) => setBrightness(newValue)}
                    min={0}
                    max={200}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>Contrast</Typography>
                  <Slider
                    value={contrast}
                    onChange={(e, newValue) => setContrast(newValue)}
                    min={0}
                    max={200}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>Saturation</Typography>
                  <Slider
                    value={saturation}
                    onChange={(e, newValue) => setSaturation(newValue)}
                    min={0}
                    max={200}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>Posterization Levels</Typography>
                  <Slider
                    value={posterizeLevels}
                    onChange={(e, newValue) => setPosterizeLevels(newValue)}
                    min={2}
                    max={64}
                    step={1}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>Hue Rotation</Typography>
                  <Slider
                    value={hue}
                    onChange={(e, newValue) => setHue(newValue)}
                    min={0}
                    max={360}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>Sharpness</Typography>
                  <Slider
                    value={sharpness}
                    onChange={(e, newValue) => setSharpness(newValue)}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                  />
                  <Typography gutterBottom>
                    Output Width (in characters)
                  </Typography>
                  <Slider
                    value={outputWidth}
                    onChange={(e, newValue) => setOutputWidth(newValue)}
                    min={20}
                    max={MAX_ASCII_WIDTH}
                    step={1}
                    valueLabelDisplay="auto"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inverted}
                        onChange={e => setInverted(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Invert Image"
                  />
                  <Button
                    variant="outlined"
                    onClick={resetSliders}
                    sx={{ mt: 2, mb: 2, ml: 2 }}
                  >
                    Reset Adjustments
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default AsciiArtGenerator
