// textAnalyzerWorkerHelper.js
import Worker from '../workers/grayscaleAdjustment.worker'

class GrayscaleAdjustmentWorkerHelper {
  constructor() {
    this.worker = new Worker()
  }

  grayscale({ imageData, posterizeLevels }) {
    return new Promise((resolve, reject) => {
      const messageHandler = e => {
        resolve(e.data)
      }

      const errorHandler = error => {
        this.worker.removeEventListener('error', errorHandler)
        reject(error)
      }

      this.worker.addEventListener('message', messageHandler)
      this.worker.addEventListener('error', errorHandler)

      this.worker.postMessage({ imageData, posterizeLevels })
    })
  }

  terminate() {
    this.worker.terminate()
  }
}

export default GrayscaleAdjustmentWorkerHelper
