// textAnalyzerWorkerHelper.js
import Worker from '../workers/colourAdjustment.worker'

class ColourAdjustmentWorkerHelper {
  constructor() {
    this.worker = new Worker()
  }

  adjustColours({
    imageData,
    width,
    height,
    brightness,
    contrast,
    saturation,
    hue,
    sharpness,
    inverted
  }) {
    return new Promise((resolve, reject) => {
      const messageHandler = e => {
        resolve(e.data)
      }

      const errorHandler = error => {
        this.worker.removeEventListener('error', errorHandler)
        reject(error)
      }

      this.worker.addEventListener('message', messageHandler)
      this.worker.addEventListener('error', errorHandler)

      this.worker.postMessage({
        imageData,
        width,
        height,
        brightness,
        contrast,
        saturation,
        hue,
        sharpness,
        inverted
      })
    })
  }

  terminate() {
    this.worker.terminate()
  }
}

export default ColourAdjustmentWorkerHelper
