// theme.js

import { createTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
  palette: {
    mode: 'light'
  }
})

export const darkTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'VT323', 'Courier New', monospace",
      textShadow: '0 0 0.08em rgba(255, 255, 255, 1), -0.08em 0 0.08em rgba(0, 255, 255, 1), 0.08em 0 0.08em rgba(255, 0, 255, 1)'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1CD3CF',
      dark: '#1A1A1A',
      gray: '#CCC'
    },
    secondary: {
      main: '#E91BFA',
      dark: '#26262B'
    }
  }
})
