// src/utils/asciiArtEncoderWorkerHelper.js
import Worker from '../workers/asciiEncoder.worker.js'

class AsciiArtEncoderWorkerHelper {
  constructor() {
    this.worker = new Worker()
  }

  encode(text, customCharSets) {
    return new Promise((resolve, reject) => {
      const messageHandler = e => {
        this.worker.removeEventListener('message', messageHandler)
        resolve(e.data)
      }

      const errorHandler = error => {
        this.worker.removeEventListener('error', errorHandler)
        reject(error)
      }

      this.worker.addEventListener('message', messageHandler)
      this.worker.addEventListener('error', errorHandler)

      this.worker.postMessage({
        text,
        customCharSets,
        useCustom: !!customCharSets
      })
    })
  }

  terminate() {
    this.worker.terminate()
  }
}

export default AsciiArtEncoderWorkerHelper
